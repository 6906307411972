import * as React from "react";
// import data_school from '../data_school.json';
import TumbonAddr from "../../util/TumbonAddr.json";
import { Select } from "antd";
const { Option } = Select;

export default function Filter({
  onChange,
  data,
  optionYear = [],
  optionSungkud = [],
}) {
  const [view, setView] = React.useState(data?.view);
  const [year, setYear] = React.useState(data?.year);
  const [province, setProvince] = React.useState(data?.province);
  const [district, setDistrict] = React.useState(data?.city_name);
  const [subDistrict, setSubDistrict] = React.useState(data?.tumbon_name);
  const [provinceId, setProvinceId] = React.useState(data?.province_name);
  const [districtId, setDistrictId] = React.useState(data?.city_name);
  const [subDistrictId, setSubDistrictId] = React.useState(data?.tumbon_name);
  const [area, setArea] = React.useState(data?.area);
  const [sungkud, setSungkud] = React.useState(data?.sungkud);

  // filter.province

  React.useEffect(() => {
    setProvince(data?.province_name)
    setDistrict(data?.city_name)
    // setSchool(mapData(dataSummaryAllyear, filter))
  }, [data]);

  return (

    <div className="code-box-shape4 queue-anim-demo flex flex-col lg:flex-row" >
      <>
        <div className="mx-2">
          <h5 className="text-sm m-0 ml-[9px]" > ปีการศึกษา: </h5>
          <Select
            className='new-design-select'
            placeholder="ปีการศึกษา"
            showSearch
            style={{ width: 120, margin: 4, fontFamily: "Kanit" }}
            defaultValue="2565"
            optionFilterProp="children"
            value={year}
            onChange={(val) => {
              setYear(val);
              setProvince(null);
              setDistrict(null);
              setSubDistrict(null);
              onChange({
                area: null,
                city_name: null,
                sungkud: null,
                tumbon_name: null,
                type: "all",
                view: "province",
                year: `${val}`,
              });
            }}
          >
            {optionYear.map((x) => (
              <Option value={x.value}>{x.text}</Option>
            ))}
          </Select>
        </div>
        <div className="mx-2">
          <h5 className="text-sm m-0 ml-[9px]" >จังหวัด: </h5>
          <Select
            className='new-design-select'
            placeholder="จังหวัด"
            showSearch
            value={province ? province : 'ทั้งหมด'}
            disabled={true}
            style={{ width: 150, margin: 4, fontFamily: "Kanit" }}
            optionFilterProp="children"
          >
          </Select>
        </div>
        <div className="mx-2">
          <h5 className="text-sm m-0 ml-[9px]" >อำเภอ: </h5>
          <Select
            className='new-design-select'
            placeholder="อำเภอ"
            city
            showSearch
            disabled={province === null}
            style={{ width: 150, margin: 4, fontFamily: "Kanit" }}
            optionFilterProp="children"
            value={district ? district : 'ทั้งหมด'}
            onChange={(val) => {
              if (val === 'ทั้งหมด') {
                setProvince(null);
                setDistrict(null);
                setSubDistrict(null);
                onChange({
                  area: null,
                  city_name: null,
                  sungkud: null,
                  tumbon_name: null,
                  type: "all",
                  view: "province",
                  year: `${year}`,
                });
              } else {
                setDistrict(val);
                onChange({
                  tumbon_name: null,
                  city_name: val,
                  view: "city",
                  year: `${year}`,
                });
              }
            }}
          >
            <Option value={'ทั้งหมด'}>{'ทั้งหมด'}</Option>
            {TumbonAddr.filter(
              (x) => province === x.provincename || province === null
            )
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.cityid === value.cityid)
              )
              .map((x) => (
                <Option value={x.cityname}>{x.cityname}</Option>
              ))}
          </Select>
        </div>
        <div className="mx-2">
          <h5 className="text-sm m-0 ml-[9px]" >ตำบล: </h5>
          <Select
            className='new-design-select'
            placeholder="ตำบล"
            city
            showSearch
            disabled={district === null}
            style={{ width: 150, margin: 4, fontFamily: "Kanit" }}
            optionFilterProp="children"
            value={subDistrict ? subDistrict : 'ทั้งหมด'}
            onChange={(val) => {
              if (val === 'ทั้งหมด') {
                setProvince(null);
                setDistrict(null);
                setSubDistrict(null);
                onChange({
                  area: null,
                  sungkud: null,
                  tumbon_name: null,
                  type: "all",
                  view: "city",
                  year: `${year}`,
                });
              } else {
                setSubDistrict(val);
                onChange({
                  tumbon_name: val,
                  view: "tumbon",
                  year: `${year}`,
                });
              }
            }}
          >
            <Option value={'ทั้งหมด'}>{'ทั้งหมด'}</Option>
            {TumbonAddr.filter(
              (x) =>
                (province === x.provincename && x.cityname === district) ||
                district === null
            )
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.tumbonid === value.tumbonid)
              )
              .map((x) => (
                <Option value={x.tumbonname}>{x.tumbonname}</Option>
              ))}
          </Select>
        </div>
      </>

    </div >
  );
}
